import { Typography, TextField, Button } from '@mui/material';
import { useState } from 'react';
import './LoginPage.css'

function LoginPage(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invalid, setInvalid] = useState(false)

  async function onSubmit(e) {
    e.preventDefault();

    const res = await fetch(props.endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "Email": email,
        "Password": password
      })
    })

    setPassword("")
    setInvalid(false)

    if (res.status === 400) {
      setInvalid(true)
      return
    }

    if (res.status !== 200) {
      alert(`Der skete en fejl, prøv igen. (Reponse code ${res.status})`)
      return
    }

    const jwt = await res.text()

    props.onLogin(jwt)
  }

  return (
    <div className="LoginPage">
      <div className="LoginPage-container">
        <div className="LoginPage-title">
          <Typography sx={{mb:2}} variant="h4">
            Login
          </Typography>
        </div>
        <form className="LoginPage-form" onSubmit={onSubmit}>
          <TextField sx={{mb:1}} type="email" label="Email" variant="standard" value={email} onChange={e => setEmail(e.target.value)} required />
          <TextField sx={{mb:2}} type="password" label="Adgangskode" variant="standard" value={password} onChange={e => setPassword(e.target.value)} required />
          <Button sx={{mb:2}} type="submit" variant="contained">Login</Button>
          {invalid &&
            <Typography variant="caption" sx={{color: 'red'}}>Email og/eller adgangskode var forkert</Typography>}
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
