import { useEffect, useState } from 'react';
import CreateScenarioForm from '../components/CreateScenarioForm';
import './DashboardPage.css'
import moment from 'moment';

import ScenarioList from '../components/ScenarioList';
import { Typography } from '@mui/material';

function DashboardPage(props) {
  const [scenarios, setScenarios] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_API_URL}/guideapp/organization/${process.env.REACT_APP_ORG_ID}/scenario`, {
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(res => res.json())
      .then(res => {
        setScenarios(res)
        setLoading(false)
      })

  }, [props.jwt])

  function handleNewScenario(s) {
    console.log(s)
    setScenarios([...scenarios, s])
  }

  function removeScenario(id) {
    var res = []
    for (const scenario of scenarios) {
      if (scenario.id === id) continue
      res.push(scenario)
    }
    setScenarios(res)
  }

  scenarios.sort((a, b) => {
    const am = moment(a.eventStart)
    const bm = moment(b.eventStart)
    if (am.isBefore(bm)) return -1;
    if (am.isAfter(bm)) return 1;
    return 0;
  })

  async function handleDelete(id) {
    setLoading(true)
    await fetch(`${process.env.REACT_APP_BASE_API_URL}/guideapp/organization/${process.env.REACT_APP_ORG_ID}/scenario/${id}`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(res => {
      if (res.status !== 200) {
        alert("Der skete en fejl og eventet kunne derfor ikke slettes, prøv igen.")
        return
      }
      removeScenario(id)
    }).catch(err => {
      alert(`Der skete en fejl og eventet kunne derfor ikke slettes, prøv igen. (${err})`)
      return
    })
    setLoading(false)
  }

  return (
    <div className="DashboardPage">
      <div>
        <Typography variant="overline">
          Opret event
        </Typography>
        <CreateScenarioForm jwt={props.jwt} onNewScenario={handleNewScenario} />
      </div>
      <div>
        <Typography variant="overline">
          Registrerede events
        </Typography>
        {loading &&
          <p>Henter events...</p>}
        <ScenarioList scenarios={scenarios} onDelete={handleDelete} />
      </div>
    </div>
  );
}

export default DashboardPage;
