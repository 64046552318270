import Scenario from '../components/Scenario';
import { Box } from '@mui/material';

function ScenarioList(props) {
  const scenarios = props.scenarios
  return (
    <div>
      {scenarios
        .map((s, i, _) =>
        <Box sx={{p:1}}>
          <Scenario key={s.id} scenario={s} onDelete={props.onDelete} />
        </Box>)}
    </div>
  )
}

export default ScenarioList;