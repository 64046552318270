import { useState } from 'react';
import './App.css';
import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const [jwt, setJwt] = useState("")
  const loggedIn = jwt.length > 0

  function handleJwt(jwt) {
    setJwt(jwt)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <header className="App-header">
          <h1 className="title">Gigantium Parkering App Dashboard</h1>
        </header>
        {!loggedIn &&
          <LoginPage endpoint={`${process.env.REACT_APP_BASE_API_URL}/auth/login`} onLogin={handleJwt} />}
        {loggedIn &&
          <DashboardPage jwt={jwt} />}
      </div>
    </ThemeProvider>
  );
}

export default App;
