import { Typography, Card, CardActions, CardContent, Button, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import moment from 'moment';
import './Scenario.css'

function Scenario(props) {
  const scenario = props.scenario
  console.log(scenario)
  const eventStart = moment.utc(scenario.eventStart)
  const eventEnd = moment.utc(scenario.eventEnd)
  const scenarioStart = moment.utc(scenario.scenarioStart)
  const scenarioEnd = moment.utc(scenario.scenarioEnd)
  const dateFormat = "D MMMM YYYY, HH:mm"

  function formatDatePeriod(start, end) {
    let localStart = moment(start).local()
    let localEnd = moment(end).local()
    let endDateFormat = dateFormat
    if (localStart.format("YYYYMMDD") === localEnd.format("YYYYMMDD")) {
      endDateFormat = "HH:mm"
    }
    return `${localStart.format(dateFormat)} to ${localEnd.format(endDateFormat)}`;
  }

  const title = scenario.title
  const scenarioFreeBus = scenario.freeBus
  const eventPeriod = formatDatePeriod(eventStart, eventEnd)
  const scenarioPeriod = formatDatePeriod(scenarioStart, scenarioEnd)

  var scenarioTypeText = "Ukendt"
  if (scenario.type === 0) scenarioTypeText = "Let";
  if (scenario.type === 1) scenarioTypeText = "Medium";
  if (scenario.type === 2) scenarioTypeText = "Høj";

  function handleDelete(e) {
    e.preventDefault()
    props.onDelete(scenario.id)
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {scenarioTypeText} scenarie
        </Typography>
        <Typography variant="subtitle1">
          Scenarie periode
        </Typography>
        <Typography variant="body">
          {scenarioPeriod}
        </Typography>
        <Typography sx={{ mt: 1.5 }} variant="subtitle1">
          Event periode
        </Typography>
        <Typography variant="body">
          {eventPeriod}
        </Typography>
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={scenarioFreeBus} disabled="disabled" />} label="Gratis bus billet" />
        </FormGroup>
      </CardContent>
      <CardActions>
        <Button size="small" color="warning" onClick={handleDelete}>Slet</Button>
      </CardActions>
    </Card>
    // <div className="Scenario">
    //   <h3>{title}</h3>
    //   <p>
    //     <label className="Scenario-label">Scenarie</label><br/>
    //     {scenarioPeriod}
    //   </p>
    //   <p>
    //     <label className="Scenario-label">Event</label><br/>
    //     {eventPeriod}
    //   </p>
    //   <label className="Scenario-checkbox-label">
    //     <input type="checkbox" checked={scenario.freeBus} disabled={true} />
    //     Gratis bus
    //   </label>
    //   <ScenarioType scenarioType={scenario.type} />
    //   <div className="Scenario-actions">
    //     <button onClick={handleDelete}>Slet</button>
    //     {/* <button onClick={handleUpdate}>Update</button> */}
    //   </div>
    // </div>
  )
}

export default Scenario;
