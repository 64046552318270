import { useState } from 'react';
import './CreateScenarioForm.css'
import "react-datetime/css/react-datetime.css";
import "moment/locale/da";
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker, LocalizationProvider  } from '@mui/x-date-pickers';
import { TextField, Checkbox, FormControlLabel, FormGroup, Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

function CreateScenarioForm(props) {
  const defaultType = 1
  const defaultScenarioStart = moment().add(-2, "h")
  const defaultScenarioEnd = moment().add(2, "h")
  const defaultEventStart = moment()
  const defaultEventEnd = moment()
  const defaultTitle = ""
  const defaultFreeBus = true

  const [scenarioType, setType] = useState(defaultType)
  const [scenarioStart, setScenarioStart] = useState(defaultScenarioStart)
  const [scenarioEnd, setScenarioEnd] = useState(defaultScenarioEnd)
  const [eventStart, setEventStart] = useState(defaultEventStart)
  const [eventEnd, setEventEnd] = useState(defaultEventEnd)
  const [scenarioTitle, setTitle] = useState(defaultTitle)
  const [scenarioFreeBus, setFreeBus] = useState(defaultFreeBus)

  function resetForm() {
    setType(defaultType)
    setScenarioStart(defaultScenarioStart)
    setScenarioEnd(defaultScenarioEnd)
    setEventStart(defaultEventStart)
    setEventEnd(defaultEventEnd)
    setTitle(defaultTitle)
    setFreeBus(defaultFreeBus)
  }

  async function onSubmit(e) {
    e.preventDefault()

    const res = await fetch(`${process.env.REACT_APP_BASE_API_URL}/guideapp/organization/${process.env.REACT_APP_ORG_ID}/scenario`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${props.jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "Type": scenarioType,
        "EventStart": eventStart,
        "EventEnd": eventEnd,
        "ScenarioStart": scenarioStart,
        "ScenarioEnd": scenarioEnd,
        "Title": scenarioTitle,
        "FreeBus": scenarioFreeBus,
      })
    })

    if (res.status !== 200) {
      alert(`Der skete en fejl og dit scenarie blev ikke oprettet. Prøv igen. (Response core ${res.status})`)
      return
    }

    resetForm()

    const json = await res.json();
    props.onNewScenario(json)
  }

  function canSubmit() {
    return moment(eventStart).isValid()
        && moment(eventEnd).isValid()
        && moment(scenarioStart).isValid()
        && moment(scenarioEnd).isValid()
        && scenarioTitle.length > 0
        && scenarioType > 0;
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <form className="CreateScenarioForm-form" onSubmit={onSubmit}>
          <div className="CreateScenarioForm-grid-name">
            <TextField id="standard-basic" label="Navn" variant="standard" value={scenarioTitle} onChange={e => setTitle(e.target.value)} required />
          </div>
          <div className="CreateScenarioForm-grid-type">
            <FormControl fullWidth>
              <InputLabel id="select-scenario-type-label">Parkeringsudfordringer</InputLabel>
              <Select
                labelId="select-scenario-type-label"
                id="select-scenario-type"
                value={scenarioType}
                label="Parkeringsudfordringer"
                onChange={e => setType(parseInt(e.target.value))}
              >
                <MenuItem value={1}>Mellem</MenuItem>
                <MenuItem value={2}>Høj</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="CreateScenarioForm-grid-scenario_datetime">
            <span className="CreateScenarioForm-section-label">Scenarie</span>
            <Box sx={{pt: 1}}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Start tid"
              value={scenarioStart}
              onChange={(newValue) => {
                setScenarioStart(newValue);
              }}
            />
            </Box>
            <Box sx={{pt: 1}}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Slut tid"
              value={scenarioEnd}
              onChange={(newValue) => {
                setScenarioEnd(newValue);
              }}
            />
            </Box>
          </div>
          <div className="CreateScenarioForm-grid-event_datetime">
            <span className="CreateScenarioForm-section-label">Event</span>
            <Box sx={{pt: 1}}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Start tid"
                value={eventStart}
                onChange={(newValue) => {
                  setEventStart(newValue);
                  setScenarioStart(moment(new Date(newValue)).add(-2, "h"))
                }}
              />
            </Box>
            <Box sx={{pt: 1}}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} />}
                label="Slut tid"
                value={eventEnd}
                onChange={(newValue) => {
                  setEventEnd(newValue);
                  setScenarioEnd(moment(new Date(newValue)).add(2, "h"))
                }}
              />
            </Box>
          </div>
          <label htmlFor="freeBus" className="CreateScenarioForm-grid-checkbox">
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={scenarioFreeBus} onChange={e => setFreeBus(e.target.checked)} />} label="Gratis bus billet" />
          </FormGroup>
          </label>
          <div className="CreateScenarioForm-grid-submit">
            <Button type="submit" variant="contained" disabled={!canSubmit()}>Opret</Button>
          </div>
          {/* {invalid &&
            <p className="DashboardPage-error">Invalid credentials</p>} */}
        </form>
      </LocalizationProvider>
    </div>
  );
}

export default CreateScenarioForm;
